.container {
  text-align: center;
  font-weight: 500;
}

.list {
  list-style: none;
  padding: 0;
}

.list > li > h2 {
  font-weight: 300;
  margin: 2rem 0 0.5rem;
}

.list > li:nth-child(1) > h2 {
  margin-top: 0;
}

.list > li > p {
  font-weight: 300;
  margin-top: 0;
}

.socialsList > a > img {
  padding: 0 1rem;
}

.or {
  padding-bottom: 15px;
  font-size: 1rem;
  color: #555;
}

.emailUs {
  font-size: 1.25rem;
  color: #555;
}

.emailUs a {
  color: #555;
}
