.footer {
  border-top: 1px solid #000;
  text-align: center;
  padding: 5px 5px 35px;
  font-size: 0.75rem;
}

.instagramIconLink {
  display: inline-block;
  margin-bottom: 5px;
}
