.container {
  text-align: center;
  font-weight: 500;
}

.profilePicture {
  filter: grayscale(100%) drop-shadow(2px 2px 6px rgba(149, 149, 149, 0.5));
  width: 100%;
  max-width: 380px;
  border-radius: 5px;
}

.infoList {
  list-style: none;
  padding: 0;
}

.infoList > li > h2 {
  font-weight: 300;
  margin: 2rem 0 0.5rem;
}

.infoList > li:nth-child(1) > h2 {
  margin-top: 0;
}

.infoList > li > p {
  font-weight: 300;
  margin-top: 0;
}
