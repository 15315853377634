.logoText {
  max-width: 75%;
}

.logoTextContainer {
  padding-bottom: 15px;
  margin-top: -15px;
}

@media screen and (min-width: 768px) {
  .logoTextContainer {
    display: none;
  }
}
