.loadingIcon {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 47px;
}
.loadingIcon div {
  display: inline-block;
  position: absolute;
  left: 10px;
  width: 5px;
  top: 28%;
  height: 50%;
  background-color: #fff;
  animation: loadingIconAnimation 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loadingIcon div:nth-child(1) {
  left: 10px;
  animation-delay: -0.15s;
}
.loadingIcon div:nth-child(2) {
  left: 32px;
  animation-delay: -0.075s;
}
.loadingIcon div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes loadingIconAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
  font-family: inherit;
}

.validationError {
  margin-top: -25px;
  margin-bottom: 15px;
  color: red;
  text-align: left;
  font-size: 14px;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}
input:-moz-placeholder {
  color: #adadad;
}
input::-moz-placeholder {
  color: #adadad;
}
input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}
textarea:-moz-placeholder {
  color: #adadad;
}
textarea::-moz-placeholder {
  color: #adadad;
}
textarea:-ms-input-placeholder {
  color: #adadad;
}

.submitButton {
  outline: none !important;
  border: none;
  background: transparent;
}

.submitButton:hover {
  cursor: pointer;
}

/*//////////////////////////////////////////////////////////////////
[ Contact ]*/

.containerContact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
}

.wrapContact {
  width: 500px;
  border-radius: 10px;
}

/*------------------------------------------------------------------
[ Form ]*/

.contactForm {
  width: 100%;
}

.contactFormTitle {
  font-size: 28px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 20px;
}

/*------------------------------------------------------------------
[ Input ]*/

.wrapInput {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
  margin-bottom: 27px;
  text-align: left;
}

.labelInput {
  font-size: 13px;
  color: #666666;
  line-height: 1.5;
  width: 100%;
  font-size: 14px;
}

.input {
  display: block;
  width: calc(100% - 10px);
  background: transparent;
  font-size: 16px;
  line-height: 1.2;
  padding-left: 6px;
  font-weight: 500;
  color: hsl(0, 0%, 20%);
}

.focusInput {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  padding-left: 6px;
}

.focusInput::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.4s;
  background: #7f7f7f;
}

/*---------------------------------------------*/
input.input {
  height: 40px;
}

textarea.input {
  min-height: 110px;
  padding-top: 9px;
  padding-bottom: 13px;
  resize: none;
}

.input:focus + .focusInput::before {
  width: 100%;
}

.select:focus-within + .focusInput::before {
  width: 100%;
}

.hasVal.input + .focusInput::before {
  width: 100%;
}

/*------------------------------------------------------------------
[ Button ]*/
.containerContactFormButton {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrapContactFormButton {
  width: 90%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
}

.contactFormButton {
  padding: 0 20px;
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: #fff;
  background-color: #000;
  line-height: 1.2;
  cursor: pointer;
  border: none;
}

.contactFormButton:disabled,
button[disabled] {
  background-color: #999;
  color: #aaa;
}

.wrapContactFormButton:hover .contactForm-bgbtn {
  left: 0;
}

.contactFormButton i {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.contactFormButton:hover i {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validateInput {
  position: relative;
}

.alertValidate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 58%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2px;
  pointer-events: none;

  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alertValidate::after {
  content: "\f06a";
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  top: 58%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
}

.alertValidate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alertValidate::before {
    visibility: visible;
    opacity: 1;
  }
}
