.col {
  margin: 1rem 0 0 0;
  text-align: center;
}

.categoryImage {
  transition: transform 0.1s linear, filter 0.1s linear;
  border-radius: 5px;
  filter: drop-shadow(2px 2px 6px rgba(149, 149, 149, 0.5));
  height: auto;

  &:hover {
    transform: translate(0px, -2px);
    filter: drop-shadow(4px 4px 8px rgba(149, 149, 149, 0.5));
  }
}
