@import-normalize;
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}
/* @media (prefers-color-scheme: dark) {
  body {
    background-color: black;
  }
} */

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

canvas {
  vertical-align: middle;
}

/* TODO: This is bad for accesibility: revisit */
button:focus {
  outline: 0 !important;
}

button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
}

img {
  margin: 1rem 0rem;
}

.react-viewer-toolbar {
  padding-bottom: 70px !important;

  & li {
    width: 50px !important;
    height: 50px !important;
    line-height: 60px !important;

    & .react-viewer-icon {
      font-size: 25px;
    }
  }
}

.react-viewer-close {
  width: 50px !important;
  height: 50px !important;
  border-radius: 0 0 0 50px !important;

  & > i {
    top: 8px !important;
    left: 18px !important;
    font-size: 25px !important;
    font-weight: 100 !important;
  }
}
