@media screen and (min-width: 768px) {
  .mobileHeader {
    display: none;
  }
}

.desktopHeader {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .desktopHeader {
    display: none;
  }
}

.mobileHeader {
  background-color: white;
  height: 70px;
  text-align: center;
}

.mobileNav {
  height: 0vh;
  width: 100%;
  background-color: white;
  overflow: hidden;
}

.openNav {
  height: 100vh;
  /* TODO: Fix scroll to top on menu open -webkit-transition: height 0.8s;
  -moz-transition: height 0.8s;
  transition: height 0.8s;
  transition-timing-function: ease-out; */
}

.headroom {
  z-index: 10000 !important;
}

@media screen and (min-width: 768px) {
  .openNav {
    display: none;
  }
}

.mobileNav {
  text-align: center;
}

.mobileNav li {
  padding: 10px 0px;
  font-size: 1.375rem;
}

.mobileNav ul {
  margin: 0;
  margin-top: 20px;
  padding: 20px;
  list-style-type: none;
}

.mobileNavLink {
  display: block;
}

.shadow {
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
}

.desktopNav {
  background-color: white;
  height: 56px;
  padding: 10px;
}

.desktopNav ul {
  margin: 0;
  padding: 0;
}

.desktopNav li {
  display: inline-block;
  vertical-align: middle;
  padding: 8px 20px;
}

.sticky {
  z-index: 10000;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
}

a:any-link {
  color: black;
}

a:-webkit-any-link {
  color: black;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  position: absolute;
  top: 7.5px;
  left: 0;
}
.hamburger:hover {
  opacity: 1;
}
.hamburger.is-active:hover {
  opacity: 1;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: black;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 2px;
  background-color: black;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px;
}
.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

/*
     * Slider Reverse
     */
.hamburger--slider-r .hamburger-inner {
  top: 2px;
}
.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider-r .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}
.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0;
}
.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg);
}

/* DARK THEME */
/* @media (prefers-color-scheme: dark) {
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: white;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: white;
  }
} */
